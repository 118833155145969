<template>
  <v-app>
    <main>
      <v-container
        id="user-profile"
        fluid
        tag="section"
      >
        <br>
        <v-row
          align="center"
          justify="center"
        >
          <img
            :src="logo"
          >
        </v-row>
        <br>
        <v-card
          class="my-base-vcard-style"
        >
          <br>
          <br>
          <v-row
            class="my-style"
            align="center"
            justify="center"
          >
            <v-col
              class="elevation-10"
              cols="12"
              sm="6"
            >
              <h2 class="title-style">
                <v-icon class="icon-style">
                  mdi-lock-reset
                </v-icon> Reset Password
              </h2>
              <v-card
                class="my-vcard-style"
              >
                <v-container class="py-0">
                  <v-card-text>
                    <v-form
                      ref="form"
                      v-model="valid"
                    >
                      <br>
                      <v-text-field
                        v-model="password"
                        label="New Password*"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        :rules="passwordRules"
                        counter
                        outlined
                        required
                        @click:append="showPassword = !showPassword"
                      />
                      <v-text-field
                        v-model="confirmPassword"
                        label="Confirm Password*"
                        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showConfirmPassword ? 'text' : 'password'"
                        :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                        counter
                        outlined
                        required
                        @click:append="showConfirmPassword = !showConfirmPassword"
                      />
                    </v-form>
                  </v-card-text>
                </v-container>
              </v-card>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  class="mt-2"
                  color="#FF3700"
                  @click="newPassword()"
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
          <br>
          <br>
        </v-card>
        <dashboard-core-footer />
      </v-container>
    </main>
    <centre-spinner
      :loading="loading"
    />
  </v-app>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';
  import { cdnUrl } from 'src/app-globals';

  export default {
    name: 'CreateNewPassword',
    components: {
      'centre-spinner': spinner,
      DashboardCoreFooter: () => import('../dashboard/components/core/Footer'),
    },
    data () {
      return {
        password: '',
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
        valid: false,
        loading: false,
        passwordRules: [v => (v || '').length >= 8 || 'Not strong.Requires at least 8 characters.'],
        confirmPasswordRules: [v => !!v || 'This field is required'],
        logo: `${cdnUrl}/website/NewDBSLogo.png`,
      };
    },
    computed: {
      linkActiveId () {
        return this.$store.getters['passwordreset/getLinkActiveId'];
      },
      userId () {
        return this.$store.getters['passwordreset/getExternalId'];
      },
      passwordConfirmationRule () {
        return this.password === this.confirmPassword || 'Password must match';
      },
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (Object.keys(vm.linkActiveId).length === 0) {
          vm.$router.push({ name: 'Login' });
        } else {
          next();
        }
      });
    },
    methods: {
      newPassword () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.$store.dispatch('passwordreset/createNewPassword', {
          link_active_id: this.linkActiveId.link_active_id,
          user_id: this.userId.id,
          password: this.password,
          password_confirmed: this.passwordConfirmationRule,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Password updated successfully',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$router.push({ name: 'Login' });
        }).finally(
          this.loading = false,
          this.$store.dispatch('passwordreset/clearLinkActiveId'),
          this.$store.dispatch('passwordreset/clearExternalId'),
        );
      },
    },
  };
</script>
<style scoped>
.my-style {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 50px;
}
.title-style {
  color: #37474F;
  text-align: center;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-vcard-style {
  margin-right: 30px;
  margin-left: 30px;
  background-color: #ECEFF1;
  border-radius: 15px;
}
.my-base-vcard-style {
  background-color: #CFD8DC !important;
  border-radius: 25px;
  margin-left: 25px;
  margin-right: 25px;
}
</style>
